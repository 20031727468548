import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import App from './App';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({
   basename: process.env.PUBLIC_URL
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
   </BrowserRouter>
);
