import { Route, Routes } from 'react-router-dom';
import { FAQ, Home, AboutUs, Summits, ContactUs, Testimonials, Unsubscribe, Page404, } from './pages';
import { Footer, NavBar } from './components';
import { useScrollToTop } from './hooks/useScrollToTop';

function App() {
   useScrollToTop()
   return (
      <div className="App">
         <NavBar />
         <Routes>
            <Route path='/' element={<Home />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='summits' element={<Summits />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route path='testimonials' element={<Testimonials />} />
            <Route path='unsubscribe' element={<Unsubscribe />} />
            <Route path='*' element={<Page404/>} />
         </Routes>
         <Footer />
      </div >
   )
}

export default App
