import { useEffect, useState } from "react";
import { getData } from "../utils/apiCalls";
import { tracking } from "../utils/tracking";

export const PastEvents = ({ limit = 0 }) => {
    const [data, setData] = useState({})
    const isLoading = data.past_events?.length ? false : true
    // const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        getData('https://youngstartup.io/api/cwebsite/get_events')
            .then(res => setData(res))
            // .then(() => setIsLoading(false))
    }, [])

    return (
        <div className='past-events'>
            <h1 className='theme-liner'>Past Events</h1>
            <main>
                {isLoading && <div id="loader"></div>}
                {!isLoading && data.past_events?.slice(0, limit || data.length).map((summit, summitIdx) =>
                    <a key={summitIdx} className='event-card' onClick={() => tracking(365, summit.id)} href={summit.page_url}>
                        <img src={summit.portrait_image} alt="img" />
                        <div>
                            <h3>{summit.venue_location}</h3>
                            <h4>{summit.dates_str}</h4>
                        </div>
                    </a>
                )}
            </main>
        </div>
    )
}