import { useEffect, useState } from 'react';
import video from '../../assets/videos/homepagevideo.mp4'
import { svgs } from '../../assets/svgs';

export const HomeBanner = () => {
    const [isLoading, setIsLoading] = useState(true)

    return (
        <div className='home-banner'>
            {isLoading &&
                <div className='shimmer-wrapper'>
                    <div className='shimmer'>
                        <video style={{ visibility: 'hidden' }} preload={'auto'} onLoadedData={() => setIsLoading(false)}>
                            <source src={video} type='video/mp4' />
                        </video>
                    </div>
                </div>}
            {!isLoading &&
                <video autoPlay loop muted>
                    <source src={video} type='video/mp4' />
                </video>}
            <header>
                <h1>Where Innovation Meets Capital</h1>
                {/* <div><h1>WHERE</h1></div>
                <div className='cube-container'>
                    <div className='cube'>
                        <div className="face front">{svgs.motto1} </div>
                        <div className="face back">{svgs.motto2}</div>
                        <div className="face top">{svgs.motto3}</div>
                    </div>
                </div> */}
            </header>
        </div>
    )
}

