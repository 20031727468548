import { UpcomingSummit } from "../components/UpcomingSummit";
import { PastEvents } from "../components";

export const Summits = () => {
    document.title = "youngStartup Ventures | Summits";

    return (
        <div className='summits'>
            <UpcomingSummit />
            <PastEvents />
        </div>
    )
}