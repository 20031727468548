// HOME 
import whoWeAre from './imgs/who-we-are.png';
import whoWeAre2 from './imgs/who-we-are2.png';
import desktopBanner from './imgs/home-banner-desktop.jpg';
import mobileBanner from './imgs/home-banner-mobile.jpg';
import desktopCollage from './imgs/home-page-collage-desktop.png';
import mobileCollage from './imgs/home-page-collage-mobile.png';
import registerCard1 from './imgs/register-card-1.png';
import registerCard2 from './imgs/register-card-2.png';
import registerCard3 from './imgs/register-card-3.png';
import registerCard4 from './imgs/register-card-4.png';
import registerCard5 from './imgs/register-card-5.png';
import registerCard6 from './imgs/register-card-6.png';
//speaker cards
import uriLevine from './imgs/UriLevine.webp';
import ramShiram from './imgs/RamShiram.webp';
import peterThiel from './imgs/peterThiel.webp';
import nickSears from './imgs/nick-sears.png';
import jeffHoffman from './imgs/Jeff-Hoffman.webp';
import bobMetcalfe from './imgs/BobMetcalfe.webp';
import ericLy from './imgs/eric-ly.png';
//logos
import androidLogo from './imgs/logo-android.png';
import linkedinLogo from './imgs/logo-Linkedin.webp';
import threeComLogo from './imgs/logo-3com.webp';
import paypalLogo from './imgs/logo-paypal.webp';
import pricelineLogo from './imgs/logo-Priceline.webp';
import sherpaloLogo from './imgs/logo-sherpalo.webp';
import wazeLogo from './imgs/logo-waze.webp';

//VOLUNTEER
import volunteerBannerDesktop from './imgs/volunteer-banner-desktop.jpg';
import volunteerBannerMobile from './imgs/volunteer-banner-mobile.jpg';
import volunteerInfo from './imgs/volunteer-info.jpg';
import volunteerCards1 from './imgs/volunteer-cards-1.png';
import volunteerCards2 from './imgs/volunteer-cards-2.png';
import volunteerCards3 from './imgs/volunteer-cards-3.png';
import volunteerCollage1 from './imgs/volunteer-collage-1.png';
import volunteerCollage2 from './imgs/volunteer-collage-2.png';
import volunteerCollage3 from './imgs/volunteer-collage-3.png';
import volunteerCollage4 from './imgs/volunteer-collage-4.png';

// STARTUPS
import startupBannerDesktop from './imgs/startups-banner-desktop.jpg';
import startupBannerMobile from './imgs/startups-banner-mobile.jpg';
import startupGainingCard1 from './imgs/startup-gaining-cards-1.png';
import startupGainingCard2 from './imgs/startup-gaining-cards-2.png';
import startupGainingCard3 from './imgs/startup-gaining-cards-3.png';
import startupGainingCard4 from './imgs/startup-gaining-cards-4.png';

// SPEAKERS
import speakersBannerDesktop from './imgs/speakers-banner-desktop.jpg';
import speakersBannerMobile from './imgs/speakers-banner-mobile.jpg';
// AGENDA
import agendaBannerDesktop from './imgs/agenda-banner-desktop.jpg';
import agendaBannerMobile from './imgs/agenda-banner-mobile.jpg';
// PARTNERS
import partnersBannerDesktop from './imgs/partners-banner-desktop.jpg';
import partnersBannerMobile from './imgs/partners-banner-mobile.jpg';
// PRESS
import pressBannerDesktop from './imgs/press-banner-desktop.jpg';
import pressBannerMobile from './imgs/press-banner-mobile.jpg';
import pressInfo from './imgs/press-info.png';
// VENTURE NURTURING
import ventureBannerDesktop from './imgs/venture-nurturing-banner-desktop.jpg';
import ventureBannerMobile from './imgs/venture-nurturing-banner-mobile.jpg';
import ventureInfo from './imgs/venture-nurturing-info.png';
//  VENUE
import venueInfo from './imgs/venue-info.png';
import venueBannerDesktop from './imgs/venue-banner-desktop.jpg';
import venueBannerMobile from './imgs/venue-banner-mobile.jpg';
import venueLocation from './imgs/venue-location.png';
// REGISTER
import registerBannerDesktop from './imgs/register-banner-desktop.jpg';
import registerBannerMobile from './imgs/register-banner-mobile.jpg';

// ICONS
import googleMaps from './imgs/icon-google-maps.png';
import waze from './imgs/icon-waze.png';

//INVESTORS
import threeM from './imgs/investors-3M-Ventures.png';
import fiveH from './imgs/investors-500-Startups.png';
import arch from './imgs/investors-arch-venture.png';
import augment from './imgs/investors-Augment-Ventures.png';
import bcapital from './imgs/investors-B-Capital.png';
import bain from './imgs/investors-Bain-Ventures.png';
import bandOfAngels from './imgs/investors-BandofAngels.png';
import bdmi from './imgs/investors-BDMI.png';
import breamar from './imgs/investors-BraemarVentures.png';
import crv from './imgs/investors-crv-ventures.png';
import dcm from './imgs/investors-DCM.png';
import dcvc from './imgs/investors-DCVC.png';
import dfj from './imgs/investors-DFJ-Growth.png';
import emergence from './imgs/investors-Emergence.png';
import felicis from './imgs/investors-Felicis.png';
import generalA from './imgs/investors-General-Atlantic.png';
import generalC from './imgs/investors-General-Catalyst.png';
import generalI from './imgs/investors-General-Inception.png';
import ggv from './imgs/investors-GGV-Capital.png';
import glide from './imgs/investors-Gilde-Healthcare.png';
import greylock from './imgs/investors-Greylock.png';
import hig from './imgs/investors-HIG-Capital.png';
import hitachi from './imgs/investors-Hitachi-Ventures.png';
import index from './imgs/investors-Index-Ventures.png';
import intel from './imgs/investors-Intel-Capital.png';
import khosla from './imgs/investors-Khosla.png';
import lightspeed from './imgs/investors-Lightspeed-Venture.png';
import mckesson from './imgs/investors-McKesson-Ventures.png';
import mvm from './imgs/investors-MVM-Partners.png';
import nea from './imgs/investors-NEA.png';
import norwest from './imgs/investors-Norwest.png';
import pear from './imgs/investors-pear-ventures.png';
import qed from './imgs/investors-qed-investors.png';
import samsung from './imgs/investors-Samsung-Catalyst-Fund.png';
import sorenson from './imgs/investors-Sorenson-Capital.png';
import sozo from './imgs/investors-sozo-ventures.png';
import telus from './imgs/investors-TELUS-Ventures.png';
import union from './imgs/investors-Union-Square-Ventures.png';
import venrock from './imgs/investors-Venrock.png';
import wave from './imgs/investors-WAVE-Equity.png';

// ABOUT US
import heroAboutUs from './imgs/hero-about-us.png';
// INFO SECTION
import infoSection1 from './imgs/info-section1.png';
import infoSection2 from './imgs/info-section2.png';


export const imgs = {
   info: {
      infoSection1,
      infoSection2,
   },
   aboutUs: {
      heroAboutUs
   },
   home: {
      whoWeAre,
      whoWeAre2,
      desktopBanner,
      mobileBanner,
      desktopCollage,
      mobileCollage,
      registerCard1,
      registerCard2,
      registerCard3,
      registerCard4,
      registerCard5,
      registerCard6,
   },
   volunteer: {
      volunteerBannerDesktop,
      volunteerBannerMobile,
      volunteerCards1,
      volunteerCards2,
      volunteerCards3,
      volunteerCollage1,
      volunteerCollage2,
      volunteerCollage3,
      volunteerCollage4,
      volunteerInfo,
   },
   startups: {
      startupGainingCard1,
      startupGainingCard2,
      startupGainingCard3,
      startupGainingCard4,
      startupBannerDesktop,
      startupBannerMobile,
   },
   speakerCards: {
      uriLevine,
      ramShiram,
      peterThiel,
      nickSears,
      jeffHoffman,
      bobMetcalfe,
      ericLy,
   },
   logos: {
      androidLogo,
      linkedinLogo,
      threeComLogo,
      paypalLogo,
      pricelineLogo,
      sherpaloLogo,
      wazeLogo,
   },
   speakers: {
      speakersBannerDesktop,
      speakersBannerMobile,
   },
   agenda: {
      agendaBannerDesktop,
      agendaBannerMobile,
   },
   partners: {
      partnersBannerDesktop,
      partnersBannerMobile,
   },
   press: {
      pressBannerDesktop,
      pressBannerMobile,
      pressInfo,
   },
   ventureNurturing: {
      ventureBannerDesktop,
      ventureBannerMobile,
      ventureInfo,
   },
   venue: {
      venueInfo,
      venueBannerDesktop,
      venueBannerMobile,
      venueLocation,
   },
   register: {
      registerBannerDesktop,
      registerBannerMobile,
   },
   icons: {
      googleMaps,
      waze,
   },
   investors: {
      threeM,
      fiveH,
      arch,
      augment,
      bcapital,
      bain,
      bandOfAngels,
      bdmi,
      breamar,
      crv,
      dcm,
      dcvc,
      dfj,
      emergence,
      felicis,
      generalA,
      generalC,
      generalI,
      ggv,
      glide,
      greylock,
      hig,
      hitachi,
      index,
      intel,
      khosla,
      lightspeed,
      mckesson,
      mvm,
      nea,
      norwest,
      pear,
      qed,
      samsung,
      sorenson,
      sozo,
      telus,
      union,
      venrock,
      wave,
   },
}