import { useEffect, useRef } from "react";

export const FooterForm = () => {
   useEffect(() => {
     const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/embed/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               region: "na1",
               portalId: "536350",
               formId: "e0fd1bef-8d7d-4236-b4e9-b942db27e370",
               target: '#footerForm',
            })
         }
      })
   },[])
   

   return (
      <div className="footer-form startups-form">
         <h1>JOIN OVER <span>300K</span> INVESTORS AND INNOVATORS </h1>
         <h2>SIGN UP NOW TO STAY UP-TO-DATE WITH ALL OUR EVENTS, NEWS, DEALS AND MORE!</h2>
         <div id="footerForm"/>
      </div>
   )
}
