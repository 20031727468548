import { useEffect, useState } from "react";
import { getData } from "../utils/apiCalls";
import { svgs } from "../assets/svgs";

export const Testimonials = () => {
    document.title = "youngStartup Ventures | Testimonials";

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData('https://youngstartup.io/api/cwebsite/get_testimonials?page=testimonials')
            .then(res => { setData(res) })
            .then(() => setIsLoading(false))
    }, []);

    return (
        <div className="testimonials">
            {isLoading && <div id="loader"></div>}
            {!isLoading &&
                <section className='cards'>
                    {data.map((item, index) =>
                        <div key={index} className='testimonial-card'>
                            {/* <h6>{item.company}</h6> */}
                            {svgs.quotes}
                            <p>{item.content}</p>
                            <div>
                                <img src={item.image_url} alt={item.image_url} />
                                <div>
                                    <h5>{item.name}</h5>
                                    <h5>{item.company}</h5>
                                </div>
                            </div>
                        </div>
                    )}
                </section>}
        </div>
    )
}
