import { imgs } from "../assets/imgs";
import { InfoSection, Stats, UpcomingSummit } from "../components";

export const AboutUs = () => {
    document.title = "youngStartup Ventures | AboutUs";

    return (
        <div className='about-us'>
            <div style={{ width: '100%', aspectRatio: '200 / 55' }}>
                <img src={imgs.aboutUs.heroAboutUs} alt="img" />
            </div>
            <h1 className='theme-liner'>About Us</h1>
            <InfoSection />
            <Stats />
            <UpcomingSummit />
        </div>
    )
}