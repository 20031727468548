export async function getData(url) {
   const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         "Accept": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
   });
   return response.json();
}

export async function postData(url, data) {
   const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
   });
   return response.json();
}