import { tracking } from "../utils/tracking";

export const Page404 = () => {
    document.title = "youngStartup Ventures | Page404";


    return (
        <div style={{ backgroundColor: "var(--theme-color)", minHeight:"50vh" }}>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    padding: "3em",
                    fontWeight: "700",
                    fontSize: "2em",
                    marginTop:'100px',
                }}
            >
                404 Error - Page Not Found!
            </h1>
            <div className='btn-container'>
                <a className='btn' onClick={() => tracking(366)} href="http://youngstartup.com/upcoming">Upcoming Event</a>
            </div>
        </div>
    )
}
